import React from "react";

function TeamSelectionHeader(props) {
    return (
        <div id={"team-selection-header"}>
            <h2 id={"team-selection-header-title"}>Fantasy Football Draft Strategy Simulator</h2>
            <div>
                <h4 className={"team-selection-header-byline"}>
                    Choose a targeted position for each round to simulate your draft strategy.
                </h4>
                <h4 className={"team-selection-header-byline"}>
                    We simulate 100,000 weeks to find how much each selection contributes to the starting lineup.
                </h4>
                <p id={'explanation-link'}><a className={"link-no-underline"} href={"#how"}>How this works</a></p>
            </div>
        </div>
    );
}

export default TeamSelectionHeader;
